import React from "react";

import { BannerWrapper, StyledBannerA } from "./styled";

const Banner = ({ href, image, styles }) => (
  <BannerWrapper image={image} styles={styles}>
    <StyledBannerA
      href={href}
      styles={styles}
      target="_blank"
      alt="banner image"
    />
  </BannerWrapper>
);

export default Banner;
