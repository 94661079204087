import styled from "styled-components";

export const BannerWrapper = styled.div`
  display: flex;
  justify-content: center;
  background-color: transparent;
  background-image: url(${({ image }) => `${image}`});
  background-position: 50% 0%;
  background-size: ${({ styles }) =>
    `${styles.backgroundSize ? styles.backgroundSize : "auto"}`};
  background-repeat: no-repeat;
  overflow: hidden;
`;

export const StyledBannerA = styled.a`
  display: flex;
  width: ${({ styles }) => styles.screenWidth ? `${styles.screenWidth}px` : "100%"};
  height: 100%;
  text-decoration: none;
  border: none;
  outline: none;
`;
