import styled from "styled-components";

export const StyledFlexibleBlock = styled.div`
  position: relative;
  display: ${({ styles }) => `${styles.display}`};
  grid-template-rows: ${({ styles }) => `${styles.gridTemplateRows}`};
  align-content: ${({ styles }) => `${styles.alignContent}`};
  background-color: ${({ styles }) => `${styles.backgroundColor}`};
  background-image: url(${({ styles }) => `${styles.backgroundImage}`});
  background-size: ${({ styles }) => `${styles.backgroundSize || "cover"}`};
  background-position: 50%;
  background-repeat: no-repeat;
`;
