import styled from "styled-components";

export const StyledImageBlock = styled.div`
  background-color: transparent;
  background-image: ${({ src }) => `url(${src})`};
  background-repeat: no-repeat;
  background-position: ${({ styles }) => `${styles.backgroundPosition}`};
  background-size: ${({ styles }) => `${styles.backgroundSize || "auto"}`};
  order: ${({ styles }) => `${styles.order}`};
`;
