import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";

import App from "./App";
import { parseQueryString } from "./services/helpers";
import urls from "./constants/http-urls";
import Loader from "./components/loader";

import "./index.css";

ReactDOM.render(<Loader />, document.getElementById("root"));

new Promise((resolve, reject) => {
  const {
    previewId,
    templateId,
    templatePreviewId,
    analyticsId
  } = parseQueryString();

  if (templatePreviewId) {
    axios
      .get(`${urls.templateConfig.get}/${templatePreviewId}`)
      .then(({ data }) => {
        resolve({
          config: { template: data.data, steps: [] },
          analyticsId: null,
          experienceId: null
        });
      })
      .catch(reject);
  } else if (previewId && templateId) {
    const getTemplate = () => axios.get(`${urls.template.get}/${templateId}`);
    const getExperience = () =>
      axios.get(`${urls.experienceSteps.get}/${previewId}`);

    Promise.all([getTemplate(), getExperience()])
      .then(function([{ data: template }, { data: experience }]) {
        resolve({
          config: {
            steps: experience.data.steps,
            template: { ...template.data }
          },
          analyticsId: null,
          experienceId: null
        });
      })
      .catch(reject);
  } else {
    const experienceId = window.location.pathname.split("/")[1];

    axios
      .get(`${urls.experience.get}/${experienceId}`)
      .then(({ data }) => {
        resolve({ config: data.data, analyticsId, experienceId });
      })
      .catch(reject);
  }
})
  .then(({ config, analyticsId, experienceId }) => {
    ReactDOM.render(
      <App
        steps={config.steps}
        template={config.template}
        analyticsId={analyticsId}
        experienceId={experienceId}
      />,
      document.getElementById("root")
    );
  })
  .catch(error => {
    const message = error.response ? error.response.data : error;
    console.error("Application initialization error =>", message);
  });
