import styled from "styled-components";

export const StyledA = styled.a`
  display: flex;
  height: 12vw;
  width: ${({ styles }) => `${styles.width || "100%"}`};
  padding-left: 2vw;
  padding-right: 2vw;
  margin-bottom: 2vh;
  align-items: center;
  justify-content: center;
  color: ${({ styles }) => `${styles.textColor || "#000"}`};
  background-color: ${({ styles }) => `${styles.backgroundColor}`};
  border-color: ${({ styles }) => styles.borderColor};
  border-radius: ${({ styles }) =>
    `${styles.borderRadius === "rounded" ? "25px" : 0}`};
  border-width: ${({ styles }) => `${styles.borderColor ? 1 : 0}px`};
  border-style: ${({ styles }) => `${styles.borderColor ? "solid" : "none"}`};
  font-family: ${({ styles }) => `${styles.fontFamily}`};
  font-size: ${({ styles }) => `${styles.fontSize}${styles.fontSizeDimension}`};
  font-weight: ${({ styles }) => `${styles.fontWeight}`};
  letter-spacing: ${({ styles }) => {
    const dimension = styles.letterSpacingDimension || "px";
    return `${styles.letterSpacing}${dimension}`;
  }};
  text-decoration: none;
  text-align: center;
  box-sizing: border-box;

  &:hover {
    color: ${({ styles }) => `${styles.color}`};
  }
`;

export const StyledImage = styled.img`
  max-height: 8vw;
  margin: 0 5px;
  order: ${({ styles }) => `${styles.order || -1}`};
`;
