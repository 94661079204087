import React from "react";

import { StyledA, StyledImage } from "./styled";

const Button = ({
  title = "",
  styles = {},
  href = "",
  imageSrc = "",
  imageStyles = {},
  onClick,
}) => (
  <StyledA styles={styles} href={href} target="_blank" onClick={onClick}>
    {title && <span>{title}</span>}
    {imageSrc && !!imageStyles.order && (
      <StyledImage styles={imageStyles} src={imageSrc} />
    )}
  </StyledA>
);

export default Button;
