import styled from "styled-components";

export const StyledLoader = styled.div`
  position: fixed;
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #001529;
  z-index: 100;
`;

export const StyledImage = styled.img`
  animation-name: circularRotation;
  animation-duration: 1.2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  @keyframes circularRotation {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;
