import styled from "styled-components";

export const StyledPageWrapper = styled.div`
  position: relative;
  min-height: 100%;
  display: grid;
  grid-template-rows: ${({ styles }) => `${styles.gridTemplateRows}`};
  background-color: ${({ styles }) => `${styles.backgroundColor}`};
  background-image: url(${({ styles }) => `${styles.backgroundImage}`});
  background-size: ${({ styles }) => `${styles.backgroundSize || "cover"}`};
  background-repeat: no-repeat;
`;
