import styled from "styled-components";

export const StyledText = styled.p`
  margin: 5px;
  width: 100%;
  color: ${({ styles }) => `${styles.color}`};
  font-family: ${({ styles }) => `${styles.fontFamily}`};
  font-weight: ${({ styles }) => `${styles.fontWeight}`};
  font-size: ${({ styles }) => {
    const dimension = styles.fontSizeDimension || "px";
    return `${styles.fontSize}${dimension}`;
  }};
  letter-spacing: ${({ styles }) => {
    const dimension = styles.letterSpacingDimension || "px";
    return `${styles.letterSpacing}${dimension}`;
  }};
  text-align: ${({ styles }) => `${styles.alignment || "left"}`};
  order: ${({ styles }) => `${styles.order || 0}`};
`;
