import React, { useState, useEffect } from "react";
import { get } from "lodash";

import Loader from "./components/loader";
import PageWrapper from "./components/page-wrapper";
import FlexibleBlock from "./components/flexible-block";
import ButtonsBlock from "./components/buttons-block";
import Text from "./components/text";
import Button from "./components/button";
import {
  saveClickAnalytics,
  calculateWrapperGridRows,
} from "./services/helpers";
import buttonIcon from "./assets/images/button-icon.svg";
import Banner from "./components/banner";
import ImageBlock from "./components/image-block";

const App = ({ steps, template, analyticsId, experienceId }) => {
  const [grid, setGrid] = useState(null);
  const [screenWidth, setScreenWidth] = useState(null);
  const [bannerHeight, setBannerHeight] = useState(null);

  const { config } = template;

  useEffect(() => {
    if (!config) return;

    const calculateGridRows = async () => {
      const result = await calculateWrapperGridRows(config);

      if (result) {
        setGrid(result.grid);
        setScreenWidth(result.screenWidth);
        setBannerHeight(result.bannerHeight);
      }
    };

    calculateGridRows();
  }, [config]);

  const buttonWidth = config.button.columnLayout === "two" ? "48%" : "100%";
  const buttonWrapDirection =
    config.button.columnLayout === "two" ? "row" : "column";
  const buttonWrapJustify =
    config.button.columnLayout === "two"
      ? "space-between"
      : config.button.alignment;

  const getGridTemplateRowsValue = (target) => {
    if (
      get(config, `${target}.text.value`, "") &&
      get(config, `${target}.image.src`, "")
    ) {
      return get(config, `${target}.gridTemplateRows`, "1fr auto");
    }

    if (
      get(config, `${target}.text.value`, "") &&
      !get(config, `${target}.image.src`, "")
    ) {
      return "minmax(min-content, max-content)";
    }

    if (
      get(config, `${target}.image.src`, "") &&
      !get(config, `${target}.text.value`, "")
    ) {
      return "1fr";
    }

    return "auto";
  };

  const renderHeaderFooterSection = (target) => {
    return (
      <FlexibleBlock
        styles={{
          display: get(config, `${target}.disabled`, false) ? "none" : "grid",
          gridTemplateRows: getGridTemplateRowsValue(target),
          alignContent: get(
            config,
            `${target}.text.verticalAlignment`,
            "start"
          ),
          backgroundColor: get(config, `${target}.background.color`, ""),
          backgroundImage: get(config, `${target}.background.image.src`, ""),
        }}
      >
        {get(config, `${target}.image.src`, "") ? (
          <ImageBlock
            src={get(config, `${target}.image.src`, "")}
            styles={{
              order: get(config, `${target}.image.order`, -1),
              backgroundPosition: `${get(
                config,
                `${target}.image.backgroundHorizontal`,
                "50%"
              )} ${get(config, `${target}.image.backgroundVertical`, "50%")}`,
              backgroundSize: get(
                config,
                `${target}.image.backgroundSize`,
                "auto"
              ),
            }}
          />
        ) : null}
        {get(config, `${target}.text.value`, "") ? (
          <Text styles={get(config, `${target}.text`, {})}>
            {config[target].text.value}
          </Text>
        ) : null}
      </FlexibleBlock>
    );
  };

  if (!bannerHeight && !grid) return <Loader />;

  return (
    <PageWrapper
      styles={{
        backgroundImage: !config.background.disabled
          ? config.background.image.src
          : "",
        backgroundColor: !config.background.disabled
          ? config.background.color
          : "",
        gridTemplateRows: grid,
      }}
    >
      {!config.banner.disabled ? (
        <Banner
          href={config.banner.url}
          image={config.banner.image.src}
          styles={{
            backgroundSize: get(config, "banner.image.backgroundSize", "auto"),
            height: bannerHeight,
            screenWidth: screenWidth,
          }}
        />
      ) : null}
      {renderHeaderFooterSection("header")}
      <ButtonsBlock
        styles={{
          display: get(config, "body.disabled", false) ? "none" : "flex",
          flexGrow: 1,
          padding: "5px",
          direction: buttonWrapDirection,
          justifyContent: buttonWrapJustify,
          alignContent: config.button.alignment,
          backgroundColor: get(config, "body.color", ""),
          backgroundImage: get(config, "body.image.src", ""),
        }}
      >
        {!steps.length && (
          <Button
            title="Button title"
            styles={{ ...config.button, width: buttonWidth }}
            imageStyles={{ order: config.button.order }}
            imageSrc={buttonIcon}
          />
        )}
        {steps.map((step, i) => (
          <Button
            key={i}
            title={step.buttonName}
            styles={{ ...config.button, width: buttonWidth }}
            href={step.url}
            imageStyles={{ order: config.button.order }}
            imageSrc={step.buttonIcon}
            onClick={saveClickAnalytics({
              analyticsId,
              experienceId,
              buttonName: step.buttonName,
              url: step.url,
            })}
          />
        ))}
      </ButtonsBlock>
      {renderHeaderFooterSection("footer")}
    </PageWrapper>
  );
};

export default App;
