import styled from "styled-components";

export const StyledButtonsBlock = styled.div`
  display: ${({ styles }) => `${styles.display}`};
  flex-wrap: wrap;
  height: ${({ styles }) => `${styles.height || "auto"}`};
  padding: ${({ styles }) => `${styles.padding || "auto"}`};
  flex-direction: ${({ styles }) => `${styles.direction || "column"}`};
  justify-content: ${({ styles }) => `${styles.justifyContent || "normal"}`};
  align-content: ${({ styles }) => `${styles.alignContent || "normal"}`};
  flex-grow: ${({ styles }) => `${styles.flexGrow || 0}`};
  background-color: ${({ styles }) =>
    `${styles.backgroundColor || "transparent"}`};
  background-image: ${({ styles }) =>
    `url(${styles.backgroundImage || "none"})`};
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
`;
