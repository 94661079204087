import React from "react";

import { StyledLoader, StyledImage } from "./styled";
import spinner from "../../assets/images/spinner";

const Loader = () => (
  <StyledLoader>
    <StyledImage src={spinner} alt="" />
  </StyledLoader>
);

export default Loader;
